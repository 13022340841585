import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useOutlet} from 'reconnect.js';
import styled from 'styled-components';
import BackButton from '../Components/BackButton';
import * as Ant from 'antd';
import {errorHandler} from '../errors';
import {Link} from 'gatsby';
import Tag from '../Widgets/Tag';
import {
  PAYMENT_STATUS,
  PAYMENT_STATUS_COLOR,
  ORDER_STATE,
  getDisplayState,
  ORDER_TYPE,
} from '../Utils/OrderUtil';
import {PERIOD_STATUS} from '../dictionary';
import Dialog from '../Dialogs/PreiodDialog';
import ExtraOrderDialog from '../Dialogs/ExtraOrderDialog';
import PeriodUtil from '../Utils/PeriodUtil';
import Anchor from '../Widgets/Anchor';
import Block from '../Widgets/Block';
import Button from '../Widgets/Button';
import Hint from '../Widgets/Hint';
import Row from '../Widgets/Row';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';

const qs = require('query-string');
const appConfig = require('../data.json');

export default function PeriodDetailPage(props) {
  const {location} = props;
  const {id} = qs.parse(location.search);
  const [actions] = useOutlet('actions');
  const [record, setRecord] = useState();
  const [orders, setOrders] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openExtraDialog, setOpenExtraOrderDialog] = useState(false);
  const cart = useMemo(() => {
    if (record) {
      try {
        return JSON.parse(record.cart);
      } catch (err) {}
    }
  }, [record]);

  const getRecord = useCallback(async () => {
    try {
      if (id) {
        let resp = await actions.getPeriod(id);
        setRecord(resp);
      }
    } catch (err) {
      errorHandler(err, '取得委託單錯誤');
    }
  }, [id]);
  const getOrders = useCallback(async () => {
    try {
      if (id) {
        let resp = await actions.getOrders({
          period_order: id,
          no_page: true,
        });
        setOrders(resp);
      }
    } catch (err) {
      errorHandler(err, '取得訂單錯誤');
    }
  }, [id]);

  const terminate = useCallback(async () => {
    actions.setLoading(true);
    try {
      await actions.terminatePeriod({period_id: record.id});
      Ant.message.success('終止委託單成功');
      getRecord();
    } catch (err) {
      errorHandler(err, '終止委託單錯誤');
    }
    actions.setLoading(false);
  }, [record]);

  useEffect(() => {
    (async () => {
      actions.setLoading(true);
      await Promise.all([getRecord(), getOrders()]);
      actions.setLoading(false);
    })();
  }, [getRecord, getOrders]);

  const {Status, Message, Result = {}} = useMemo(() => {
    try {
      let result = JSON.parse(record.payment_transaction_detail);
      return result;
    } catch (err) {
      return {};
    }
  }, [record]);

  if (!record) {
    return (
      <Wrapper>
        <BackButton pathname="/periods/" />
        <div>載入中...</div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Row style={{marginBottom: 20}}>
        <BackButton pathname="/periods/" />
        <div style={{flex: 1}} />
        <Button
          type="primary"
          color="#7cc261"
          onClick={() => setOpenDialog(true)}
          style={{marginRight: 10}}>
          編輯
        </Button>

        <Button
          type="primary"
          onClick={() => {
            setOpenExtraOrderDialog(true);
          }}
          color="#438de1"
          style={{marginRight: 10}}>
          補收款
        </Button>
        <Button
          type="primary"
          disabled={record.status !== 'extra_payment_waiting'}
          onClick={() => {
            if (window.confirm(`確認在藍新終止此筆訂閱？`)) {
              terminate();
            }
          }}
          color={appConfig.colors.error}
          style={{}}>
          終止
        </Button>

        <Button
          shape="circle"
          style={{marginLeft: 8, border: 0}}
          onClick={async () => {
            actions.setLoading(true);
            await Promise.all([getRecord(), getOrders()]);
            actions.setLoading(false);
          }}
          icon={<Refresh color={appConfig.colors.main} size={26} />}
        />
      </Row>

      <Ant.Row gutter={[20, 20]}>
        <Ant.Col span={12}>
          <Block>
            <h2>委託單資訊</h2>
            <div className="divider" />
            <div>
              <div className="row">
                <h4>編號</h4>
                <div>{record.id}</div>
              </div>
              <div className="row">
                <h4>期數</h4>
                <div>{record.times + ' / ' + record.auth_times}</div>
              </div>
              <div className="row">
                <h4>每期金額</h4>
                <div>$ {record.period_amount}</div>
              </div>
              <div className="row">
                <h4>原價參考</h4>
                <div style={{color: '#bbb'}}>
                  $ {cart.items?.[0]?.period_original_price || '-'}
                </div>
              </div>
              <div className="row">
                <h4>總金額</h4>
                <div>$ {record.amount}</div>
              </div>

              <div className="row">
                <h4>扣款日期</h4>
                <div>{record.date_array}</div>
              </div>
              <div className="row">
                <h4>信用卡號</h4>
                <div>{record.card_no}</div>
              </div>

              <div className="row">
                <h4>委託單開立狀態</h4>
                <div
                  style={{
                    color:
                      Status === 'SUCCESS' ? '#4BAA70' : appConfig.colors.error,
                  }}>
                  {Status === 'SUCCESS' ? '成功' : Status}
                </div>
              </div>

              <div className="row">
                <h4>藍新回傳訊息</h4>
                <div>{Message}</div>
              </div>

              <div className="row">
                <h4>委託單狀態</h4>
                <Tag color={PeriodUtil.getStatusColor(record.status)}>
                  {PERIOD_STATUS[record.status]}
                </Tag>
              </div>

              <div className="row">
                <h4>取消訂閱制備註</h4>
                <div>{record.note}</div>
              </div>

              <div className="row">
                <h4>後台人員備註</h4>
                <div>{record.staff_note}</div>
              </div>

              <div className="row">
                <h4>拒絕原因</h4>
                <div>{record.reject_reason}</div>
              </div>

              <div className="row">
                <h4>建立時間</h4>
                <div>{record.created?.slice(0, 10)}</div>
              </div>

              {record.status === 'extra_payment_waiting' &&
                orders.find(
                  (x) =>
                    x.order_type === 'extra' && x.payment_status === 'success',
                ) && (
                  <div className="row">
                    <Hint type="warning">補收款付款完成，請點擊終止</Hint>
                  </div>
                )}
            </div>
          </Block>
        </Ant.Col>
        <Ant.Col span={24}>
          <Block>
            <h2>訂單資訊</h2>
            <div className="divider" />
            <Ant.Table
              columns={[
                {
                  title: '編號',
                  key: 'display_id',
                  render: (record) =>
                    record.order_type === 'period' ? (
                      <Link to={`/order/?id=${record.id}`}>
                        {record.display_id}
                      </Link>
                    ) : record.order_type === 'extra' ? (
                      <Anchor to={`/order/?id=${record.id}`}>
                        {record.display_id}
                      </Anchor>
                    ) : (
                      record.display_id
                    ),
                },
                {
                  title: '顧客姓名',
                  key: 'receiver_name',
                  render: (record) => (
                    <Link to={`/member/?id=${record.buyer_id}`}>
                      {record.receiver_name}
                    </Link>
                  ),
                },
                {
                  title: '訂單狀態',
                  key: 'order_state',
                  render: (record) =>
                    ORDER_STATE[getDisplayState({order: record})],
                },
                {
                  title: '訂單類型',
                  key: 'order_type',
                  dataIndex: 'order_type',
                  render: (value) => ORDER_TYPE[value],
                },
                {
                  title: '付款狀態',
                  key: 'payment_status',
                  dataIndex: 'payment_status',
                  render: (payment_status) => (
                    <Tag color={PAYMENT_STATUS_COLOR[payment_status] || '#000'}>
                      {PAYMENT_STATUS[payment_status] || '-'}
                    </Tag>
                  ),
                },
                {title: '總價', key: 'amount', dataIndex: 'amount'},
              ]}
              dataSource={orders}
              pagination={false}
              rowKey="id"
            />
          </Block>
        </Ant.Col>
      </Ant.Row>

      {openDialog && (
        <Dialog
          onUpdate={async () => {
            actions.setLoading(true);
            await getRecord();
            actions.setLoading(false);
          }}
          closeDialog={() => setOpenDialog(false)}
          record={record}
        />
      )}

      <ExtraOrderDialog
        visible={openExtraDialog}
        onUpdate={() => {
          getOrders();
        }}
        onClose={() => setOpenExtraOrderDialog(false)}
        options={{periodOrder: record}} // periodOrder only carry when create extra order
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;
