import React, {Fragment, useCallback, useState} from 'react';
import styled from 'styled-components';
import {Close} from '@styled-icons/material/Close';
import * as Ant from 'antd';
import {useOutlet} from 'reconnect.js';
import {errorHandler} from '../errors';
import {PERIOD_STATUS} from '../dictionary';
import Block from '../Widgets/Block';
import Button from '../Widgets/Button';
import Hint from '../Widgets/Hint';

export default function PeriodDialog(props) {
  const {onUpdate, closeDialog, record} = props;

  const [actions] = useOutlet('actions');
  const [values, setValues] = useState(record);

  const editRecord = useCallback(async () => {
    actions.setLoading(true);

    try {
      await actions.editPeriod(values);
      Ant.message.success('委託單更新完成');
      onUpdate();
      closeDialog();
    } catch (err) {
      errorHandler(err, '委託單更新錯誤');
    }
    actions.setLoading(false);
  }, [actions, values]);

  return (
    <Ant.Modal
      visible={true}
      title="編輯委託單"
      footer={
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}>
          <Button onClick={() => closeDialog()}>取消</Button>
          <Button
            type="primary"
            onClick={() => {
              editRecord();
            }}>
            {'確認'}
          </Button>
        </div>
      }
      closeIcon={
        <Close style={{position: 'absolute', right: 20, top: 20}} size={20} />
      }
      onCancel={closeDialog}
      width={900}>
      <Block>
        <div style={{padding: 0}}>
          <div className="row">
            <h4>委託單狀態</h4>
            <Ant.Select
              disabled={record.status === 'terminate'}
              value={values.status}
              onChange={(value) => {
                setValues((prev) => ({...prev, status: value}));
              }}
              style={{marginRight: 10}}>
              {Object.keys(PERIOD_STATUS).map((key) => {
                const disabled =
                  record.status === 'review_waiting' &&
                  key !== 'extra_payment_waiting' &&
                  key !== 'rejected';
                return (
                  <Ant.Select.Option value={key} key={key} disabled={disabled}>
                    {PERIOD_STATUS[key]}
                  </Ant.Select.Option>
                );
              })}
            </Ant.Select>
            <Hint type="info">
              請選擇補收款中（收款完成再執行終止程序）、或是拒絕取消
            </Hint>
          </div>

          <div className="row">
            <h4>拒絕原因</h4>
            <Ant.Input
              disabled={values.status !== 'rejected'}
              value={values.reject_reason}
              onChange={(e) => {
                const value = e.target.value;
                setValues((prev) => ({...prev, reject_reason: value}));
              }}
            />
          </div>

          <div className="row">
            <h4>取消訂閱制備註</h4>
            <div>{values.note}</div>
          </div>

          <div className="row">
            <h4>後台人員備註</h4>
            <Ant.Input.TextArea
              value={values.staff_note}
              onChange={(e) => {
                const value = e.target.value;
                setValues((prev) => ({...prev, staff_note: value}));
              }}
            />
          </div>
          {values.status === 'extra_payment_waiting' && (
            <div className="row">
              <h4>注意</h4>
              <Hint type="warning">請在委託單開立補收款</Hint>
            </div>
          )}
        </div>
      </Block>
    </Ant.Modal>
  );
}
